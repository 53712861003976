<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0">{{ title }}</h4>

          <div class="page-title-right">
            <!--  <b-breadcrumb :items="items" class="m-0"></b-breadcrumb> -->
            <div>
              <b-button
                id="show-btn"
                variant="primary"
                :to="{ name: 'clients-index' }"
                >Lista de clientes</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4 justify-content-center">
      <div class="col-xl-8">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
              <!-- <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-v"></i>
                </template>
                <a class="dropdown-item" href="#">Edit</a>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Remove</a>
              </b-dropdown> -->
            </div>
            <hr class="my-4" />
            <div class="text-muted">
              <div class="table-responsive mt-4 mb-0">
                <div>
                  <p class="mb-1">{{ $t('clients.name') }}:</p>
                  <h5 class="font-size-16">{{ item.name }}</h5>
                </div>
                <div>
                  <p class="mt-4 mb-1">{{ $t('clients.form.token') }}:</p>
                  <h5 class="font-size-16">{{ item.token }}</h5>
                </div>
                <div>
                  <p class="mt-4 mb-1">ID:</p>
                  <h5 class="font-size-16">{{ item._id }}</h5>
                </div>
                <div class="d-flex flex-column">
                  <p class="mt-4 mb-1">{{ $t('clients.logo') }}:</p>
                  <img
                    :src="item.logo"
                    alt="logo"
                    title="logo"
                    style="max-width: 200px"
                  />
                  <i class="font-size-16">{{ item.logo }}</i>
                </div>
                <div>
                  <p class="mt-4 mb-1">
                    {{ $t('clients.form.endpointAuth') }}:
                  </p>
                  <h5 class="font-size-16">{{ item.endpointAuth }}</h5>
                </div>
                <div>
                  <p class="mt-4 mb-1">{{ $t('clients.form.endpointBet') }}:</p>
                  <h5 class="font-size-16">{{ item.endpointBet }}</h5>
                </div>
                <div>
                  <p class="mt-4 mb-1">{{ $t('clients.form.endpointWin') }}:</p>
                  <h5 class="font-size-16">{{ item.endpointWin }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('clients.form.endpointRollback') }}:</p>
                  <h5 class="font-size-16">{{ item.endpointRollback }}</h5>
                </div>
                <div class="mt-4 mb-1">
                  <p class="mb-1">{{ $t('profile.creationDate') }}:</p>
                  <h5 class="font-size-16">
                    {{ new Date(item.createdAt).toDateString() }}
                  </h5>
                </div>
                <div class="mt-4 mb-1" v-if="item.roulettes">
                  <p class="mb-1">Ruletas :</p>
                  <div>
                    <b-table
                      striped
                      hover
                      :items="roulettes"
                      :fields="fields"
                    ></b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';

/**
 * Profile component
 */
export default {
  components: { Layout },
  page: {
    title: 'Cliente',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: 'Pefil del cliente',
      item: [],
      roulettes: [],
      fields: [
        {
          key: 'pleno',
          label: 'Pleno',
        },
        {
          key: 'semipleno',
          label: 'Semi pleno',
        },
        {
          key: 'calle',
          label: 'Calle',
        },
        {
          key: 'cuadro',
          label: 'Cuadro',
        },
        {
          key: 'linea',
          label: 'Línea',
        },
        {
          key: 'columna',
          label: 'Columna',
        },
        {
          key: 'docena',
          label: 'Docena',
        },
        {
          key: 'chanceSimple',
          label: 'Chance simple',
        },
        {
          key: 'cubre',
          label: 'Cubre',
        },
        {
          key: 'roundDuration',
          label: 'Duración de ronda',
        },
      ],
    };
  },
  created() {
    if (this.$router.currentRoute.params.id) {
      this.$http
        .get(`clients/${this.$router.currentRoute.params.id}`)
        .then((response) => {
          this.item = response.data.client;
          if (!this.item.roulettes) {
            this.roulettes = [];
          } else {
            this.roulettes = this.item.roulettes;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.$router.push({ path: '/clients' });
    }
  },
};
</script>
